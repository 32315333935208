var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.tokens, function(token) {
      return _c("span", { staticClass: "my-search-token" }, [
        _c(
          "button",
          {
            staticClass: "close",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.closeToken(token)
              }
            }
          },
          [_vm._v("×")]
        ),
        _vm._v("\n    " + _vm._s(token.label)),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: token.label && token.value,
                expression: "token.label && token.value"
              }
            ]
          },
          [_vm._v(":")]
        ),
        _vm._v(" " + _vm._s(token.value) + "\n\n    ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }