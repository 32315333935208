import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';

import {
	fireFormSubmit
}
from 'voUtils/TagEx.js';

import {
	doPost
}
from 'voUtils/RestEx.js';
import {
	findPreviousSiblingByName
}
from 'voUtils/BrowserEx.js';

export default {
	voVueComponent: 'voffice-form',
	data: function () {
		var fd = VOFFICE.testmode ? {
			forename: 'John',
			surname: 'Doe',
			email: 'test@test.de',
			mobile: '342344 234234',
			message: 'Hallo World!',
			mainAddress: {
				country: VOFFICE.guestcountry || 'DE',
				street: 'Am Berg',
				housenumber: '33',
				postalcode: '23730',
				city: 'Neustadt'
			}
		} : {
			mainAddress: {
				country: VOFFICE.guestcountry || 'DE',
			}
		};
		return {
			formData: fd,
			status: 'ready',
			error: undefined
		};
	},
	props: {
		subject: String,
		ngaction: String
	},
	mounted: function () {

		var labels = {};
		var order = [];

		this.$el.querySelectorAll('[data-formdata-field]').forEach((el) => {
			var field = el.getAttribute('data-formdata-field');
			let label = el.getAttribute('voffice-form-field-label');

			if (!label) {
				let labelEl = findPreviousSiblingByName(el, 'label');
				if (!labelEl) {
					let id = el.getAttribute('id');
					if (id) {
						labelEl = this.$el.querySelector('[for="' + id + '"]');
					}
				}
				if (labelEl) {
					label = labelEl.textContent;
				}

			}

			if (label) {
				labels[field] = label;
			} else {
				V.warn(`could not find label for field ${field}, please use label, 'for' or 'voffice-form-field-label' attribute`);
			}

			order.push(field);


		});

		this.labels = labels;
		this.order = order;

	},
	methods: {
		submit: function () {
			this.status = 'submitting';

			var data = {
				subject: this.subject,
				form: DateEx.formatDates(this.formData, undefined, VOFFICE.mlang),
				search: DateEx.encodeDates(this.$parent.searchData),
				unit: this.$parent.getUnitId && this.$parent.getUnitId(),
				labels: this.labels,
				order: this.order
			};


			fireFormSubmit(data);
			this.error = undefined;

			doPost(this.ngaction || 'saveMessage', data)
				.then((res) => {

					this.status = 'done';


				}).catch(e => {
					this.status = 'failed';
					this.error = e;
				});





		}
	}
};